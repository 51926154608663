<template>
  <v-container class="grey lighten-5 mb-6" fluid>
    <v-row>
      <v-col>
        <v-card class="mx-auto form-card-padding">
          <v-toolbar flat>
            <v-toolbar-title class="grey--text">
              {{ $t('product.add.title') }}
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon>
              <v-icon>mdi-plus-circle-outline</v-icon>
            </v-btn>
          </v-toolbar>

          <v-divider></v-divider>

          <v-form ref="userForm" lazy-validation>
            <v-text-field
              v-model="productData.name"
              :counter="15"
              :rules="validation.nameRules"
              :label="$t('product.add.name')"
              required
            ></v-text-field>

            <v-text-field
              v-model="productData.description"
              :counter="15"
              :rules="validation.lastnameRules"
              :label="$t('product.add.description')"
              required
            ></v-text-field>

            <v-select
              v-model="productData.rule"
              :items="productConstants.rules"
              :menu-props="{ maxHeight: '400' }"
              :label="$t('product.add.rule.label')"
              :hint="$t('product.add.rule.hint')"
              persistent-hint
            ></v-select>

            <v-select
              v-model="productData.type"
              :items="productConstants.types"
              :menu-props="{ maxHeight: '400' }"
              :label="$t('product.add.type.label')"
              :hint="$t('product.add.type.hint')"
              persistent-hint
            ></v-select>

            <v-select
              v-model="productData.group"
              :items="productConstants.groups"
              :menu-props="{ maxHeight: '400' }"
              :label="$t('product.add.group.label')"
              :hint="$t('product.add.group.hint')"
              persistent-hint
            ></v-select>

            <v-switch
              v-model="productData.active"
              :label="$t('product.add.active')"
            ></v-switch>

            <v-btn
              style="margin-top: 10px"
              color="success"
              class="mr-4 align-self-lg-end"
              @click="createProduct()"
            >
            {{ $t('product.add.controlls.create') }}
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <error-handler :error="error"></error-handler>
  </v-container>
</template>
<script>

export default {
  name: "AddProduct",
  data() {
    return {
      error: null,
      isLoading: true,
      validation: {
        nameRules: [
          (v) => !!v || "Name is required",
        ],
        description: [
          (v) => !!v || "Lastname is required",
        ],
      },
      productData: {
        name: "",
        description: "",
        rule: null,
        type: null,
        group: null,
        active: false,
      },
    };
  },
  computed: {
    applicationData() {
      return this.$store.state.applicationData
    },
    productConstants() {
      if(this.applicationData) {
        return this.applicationData.product.constants; 
      }
      return []
    }
  },
  mounted() {
    this.$store.dispatch('loadApplicationData')
  },
  methods: {
    ruleText(item){
      return this.$t(`product.add.rule.mappings.${item}`)
    },
    createProduct() {
      this.$axios({
        method: "post",
        url: this.$globals.api.product.create,
        data: this.productData,
      })
        .then(() => {
          this.$router.push({ name: "product/list" });
        })
        .catch((error) => {
          this.error = error.response;
        });
    },
  },
};
</script>